.Resume {
  text-align: center;
  height: 500vh;
  width: 100%;
  background-color: #282c34;
  color: white;
  min-height: 500vh;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

.resume-header {
    padding-top: 50px;
}

#contact-information {
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
}

.resume-spinner-text {
    position: absolute;
    width: 150px;
    scroll-behavior: smooth;
    
    &.first {
        top: -11px;
        left: -160px;
        rotate: 0deg;
        scroll-snap-align: start;
    }
    
    &.second {
        top: 85px;
        left: -76px;
        rotate: 270deg;

  scroll-snap-align: start;
    }

    &.third {
        top: -11px;
        left: 53px;
        rotate: 180deg;
    }

    &.fourth {
        top: -150px;
        left: -3px;
        rotate: 7deg;
        writing-mode: vertical-lr;
    }
}

#resume-spinner-wheel {
    position: fixed;
    top: 50%;
    left: 50%;
    scroll-snap-type: x mandatory;
    border-radius: 300px 300px 300px 300px;
    border-left: 160px solid blue;
    border-right: 160px solid red;
    border-top: 160px solid purple;
    border-bottom: 160px solid orange;
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
    animation-iteration-count: 1;
    animation-fill-mode: both;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

@keyframes rotate {
    to {
      transform: rotate(270deg);
    }
}

.resume-separater {
    display: inline-grid;
    width: 90%;
    border-bottom: solid;
}

div.resume-company {
    display: grid;
    grid-template: "company top-blank top-blank top-blank duration top-blank2"
    "position bottom-blank bottom-blank bottom-blank bottom-blank location";

    &.education {
        grid-template: "company top-blank top-blank top-blank duration top-blank2" 25px
        "position bottom-blank bottom-blank bottom-blank bottom-blank location";
    }
}

.resume-company-tag {
    grid-area: company;
}

.resume-duration-tag {
    grid-area: duration;
}

.resume-position-tag {
    grid-area: position;
    font-style: italic;
    font-family: ui-rounded;
}

.resume-location-tag {
    grid-area: location;
    font-style: italic;
    font-family: ui-rounded;
}

.resume-bullet {
    display: flex;
    padding-left: 6%;
}

.resume-description {
    width: 90%;
    margin-top: 2px;
    text-align: justify;

    &.project {
        height: 0px;
    }

    &.skills-awards {
        display: flex;
        gap: 5px;
    }
}

.resume-skills-awards {
    font-weight: bold;
}