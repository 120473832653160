.work-in-progress {
  top: 0%;
  left: 80%;
  width: 100px;
  height: 100px;
  position: fixed;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px #fffb00;
}

.work-in-progress:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background: #dad705;
  transform: rotate(45deg);
  top: 75px;
  left: 25px;
  border-radius: 5px;
  box-shadow: -1px -1px 10px -2px #a7a400;
  animation: wip-warning-pulse infinite 1.5s alternate ease-in-out;
}

.text-entry {
    position: relative;
    z-index: 2;
    top: 80%;
}

@keyframes wip-warning-pulse {
    from {
        box-shadow: -1px -1px 10px -2px #fffb00;
    }
    to {
        box-shadow: -3px -3px 30px -6px #fffb00;
    }
}