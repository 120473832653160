.under-construction {
    text-align: center;
    height: 100%;
    width: 100%;
}
  
.App-logo {
height: 40vmin;
pointer-events: none;
}
  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
  
.under-construction-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
  
.App-link {
color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.under-construction-warning-triangle {
  position: relative;
  margin-bottom: 60px;
  margin-left: 20%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px #fffb00;
  transform: scale(2);
  &:hover {
      animation: warning-pulse infinite 1.5s alternate ease-in-out;
  }
}

.under-construction-warning-triangle::after {
    
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: #dad705;
    transform: rotate(45deg);
    top: 75px;
    left: 25px;
    border-radius: 5px;
    box-shadow: -1px -1px 10px -2px #a7a400;
}

@keyframes warning-pulse {
    from {
        margin-bottom: 60px;
        transform: scale(2);
    }
    to {
        margin-bottom: 70px;
        transform: scale(2.3);
    }
}
.under-construction-warning-triangle-exclamation {
    color: black;
    position: absolute;
    padding-top: 64px;
    padding-left: 45px;
    font-size: xx-large;
    font-stretch: ultra-expanded;
    z-index: 1;
}