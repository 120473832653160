#navigation-container {
    position: fixed;
    z-index: 3;
    display: flex;
    width: 100%;
    background-color: rgb(129, 129, 129);
    border-radius: 0px 0px 20px 20px;
    box-shadow: 4px 0px 6px 3px;
    transition: height 1s ease;
}
#navigation-container:hover {
    height: 3ch;
}

.navigation-entry-container {
    display: ruby;
    width: 15%;
    height: 100%;
    padding-left: 2%;
    border-right: 1px;
    border-style: hidden;
    background-color: rgb(129, 129, 129);
    transition: background-color 2s ease;
    &.first-entry {
        margin-left: 0px;
        border-radius: 0px 0px 0px 18px;
    }
    &:hover {
        background-color: rgb(141, 141, 141);
        transition: background-color 0s ease;
    }
}

.expandable-container {
    display: inline-flex;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: inherit;
}

.expandable-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: right;
    margin-top: 9%;
    margin-left: 4px;
    transition: transform 0.6s ease;
    
}
.expandable-arrow-hover {
    transform: rotate(360deg);
    transition: transform 0.8s ease;
}
.expandable-options-list {
    display: flow;
    position: absolute;
    align-content: center;
    width: 17%;
    transition: top 1s ease, box-shadow 3s ease, z-index 2s ease;
    border-radius: 0px 0px 18px 18px;
    &.last-entry {
        border-radius: 0px 0px 18px 18px;
    }
    &:hover {
        transition: top 1s ease, box-shadow 1s ease, z-index 0s ease;
    }
}

.expandable-option {
    height: 30px;
    align-content: center;
    background-color: rgb(141, 141, 141);
    &.last-entry {
        border-radius: 0px 0px 18px 18px;
    }
    &:hover {
        background-color: rgb(150, 150, 150);
    }
}
